import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "../store";

type EmailPageState = {
  value: number;
};

const initialState: EmailPageState = {
  value: 0,
};

const slice = createSlice({
  name: "pageState",
  initialState,
  reducers: {
    emailState(state) {
      state.value = 0;
    },
    codeState(state) {
      state.value = 1;
    },
  },
});

export default slice.reducer;

export const { emailState, codeState } = slice.actions;

// export const registerPage = () => {
//   return async () => {
//     dispatch(registerState());
//   };
// };

// export const emailPage = () => {
//   return async () => {
//     dispatch(emailState());
//   };
// };

// export const codePage = () => {
//   return async () => {
//     dispatch(codeState());
//   };
// };
