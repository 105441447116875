import { useTheme } from "@mui/material";
import { Box } from "@mui/material";

type MyErrorHelperTextProps = {
  error: boolean;
  helperText?: string;
};
const MyErrorHelperText = ({ error, helperText }: MyErrorHelperTextProps) => {
  const theme = useTheme();
  return (
    <>
      {error && helperText && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "12px",
            fontFamily: ["Inter", "Public Sans"].join(","),
            fontSize: "10px",
            fontWeight: 400,
            fontStyle: "normal",
            lineHeight: "10px",
            mt: 0.25,
            color: theme.palette.error.dark,
          }}
        >
          {helperText}
        </Box>
      )}
    </>
  );
};

export default MyErrorHelperText;
