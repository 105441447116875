import { Theme } from '@mui/material/styles';
//
import { TreeViewCollapseIcon, TreeViewExpandIcon, TreeViewEndIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function TreeView(theme: Theme) {
  return {
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: <TreeViewCollapseIcon sx={{ width: 25, height: 25 }} />,
        defaultExpandIcon: <TreeViewExpandIcon sx={{ width: 25, height: 25 }} />,
        defaultEndIcon: <TreeViewEndIcon sx={{ color: 'text.secondary', width: 25, height: 25 }} />,
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: { ...theme.typography.body2, fontWeight: 400 },
        iconContainer: { width: 'auto' },
        padding:3,
        root: {
          '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label.Mui-focused': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent',
          },
        }
      },
    },
  };
}
