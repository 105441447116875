import { alpha, lighten } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module "@mui/material/styles/createPalette" {
  interface TypeText {
    greyLight: string;
    greyDark: string;
  }
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
    accent: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    greyShade: PaletteColor;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module "@mui/material" {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

const NEW_PALETTE = {
  primary: "#0b427a",
  primaryDark: "#05315d",
  secondary: "#528acc",
  secondaryDark: "#3b74b7",
  accent: "#8dd4cb",
  secondaryLight: "#EEF5FD",
  white: "#FFFFFF",
  black: "#212B36",
  grey90: "#657482",
  grey60: "#B7C1CA",
  grey35: "#E4E6EB",
  grey10: "#F4F6F8",
  errorDark: "#CE0D09",
  errorLight: "#FFEFEF",
  adviceDark: "#9B6903",
  adviceLight: "#FFF4E4",
  successDark: "#187F3F",
  successLight: "#EAF6EE",
  gradientClear: "#",
  gradientDark: "#",
};

const DARK_PALETTE = {
  primary: "#1e557f",
  primaryDark: "#0b427a",
  secondary: "#6e9ed4",
  secondaryDark: "#528acc",
  accent: "#a8e3da",
  secondaryLight: "#21303d",
  white: "#FFFFFF",
  black: "#0b0f13",
  grey90: "#49545a",
  grey60: "#7e8b93",
  grey35: "#a2a9b0",
  grey10: "#c5c9cc",
  errorDark: "#CE0D09",
  errorLight: "#441316",
  adviceDark: "#9B6903",
  adviceLight: "#3e2e0a",
  successDark: "#186535",
  successLight: "#06360a",
  gradientClear: "#21303d",
  gradientDark: "#0b0f13",
};

const GREY_PALETTE = {
  light: {
    lighter: "#F4F6F8",
    light: "#E4E6EB",
    main: "#B7C1CA",
    dark: "#657482",
    darker: "#212B36",
  },
  dark: {
    lighter: "#c5c9cc",
    light: "#7e8b93",
    main: "#7e8b93",
    dark: "#49545a",
    darker: "#0b0f13",
  },
};

const PRIMARY_PALETTE = {
  light: {
    lighter: "#C8FACD",
    light: "#84A9FF",
    main: "#3366FF",
    dark: "#1939B7",
    darker: "#091A7A",
  },
  dark: {
    lighter: "#C8FACD",
    light: "#84A9FF",
    main: "#3366FF",
    dark: "#1939B7",
    darker: "#091A7A",
  },
};

// SETUP COLORS
const PRIMARY = {
  lighter: "#C8FACD",
  light: NEW_PALETTE.primary,
  main: NEW_PALETTE.primary,
  dark: NEW_PALETTE.primaryDark,
  darker: NEW_PALETTE.primaryDark,
};
const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
};
const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#EAF6EE",
  main: "#54D62C",
  dark: "#187F3F",
  darker: "#08660D",
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFF4E4",
  main: "#FFC107",
  dark: "#9B6903",
  darker: "#7A4F01",
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFEFEF",
  main: "#FF4842",
  dark: "#CE0D09",
  darker: "#7A0C2E",
};

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary_: { ...PRIMARY, contrastText: "#fff" },
  primary: {
    light: NEW_PALETTE.primary,
    dark: NEW_PALETTE.primaryDark,
    main: NEW_PALETTE.primary,
    contrastText: "#fff",
    lighter: NEW_PALETTE.primary,
    darker: NEW_PALETTE.primaryDark,
    accent: NEW_PALETTE.accent,
  },
  secondary_: { ...SECONDARY, contrastText: "#fff" },
  secondary: {
    light: NEW_PALETTE.secondaryLight,
    dark: NEW_PALETTE.secondaryDark,
    main: NEW_PALETTE.secondary,
    contrastText: "#fff",
    lighter: NEW_PALETTE.secondaryLight,
    darker: NEW_PALETTE.secondaryDark,
  },

  info_: { ...INFO, contrastText: "#fff" },
  info: {
    ...INFO,
    light: lighten(NEW_PALETTE.secondaryLight, 0.2),
    dark: NEW_PALETTE.secondaryDark,
    contrastText: "#fff",
  },
  success_: { ...SUCCESS, contrastText: GREY[800] },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: {
    ...WARNING,
    light: NEW_PALETTE.adviceLight,
    dark: NEW_PALETTE.adviceDark,
    contrastText: GREY[800],
  },
  warning_: { ...WARNING, contrastText: GREY[800] },
  error_: { ...ERROR, contrastText: "#fff" },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  newPalette: NEW_PALETTE,
  darkPalette: DARK_PALETTE,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: "light",
    text: {
      primary: GREY[800],
      secondary: NEW_PALETTE.primary,
      disabled: GREY[500],
      greyLight: NEW_PALETTE.grey90,
    },
    background: { paper: "#fff", default: "#fff", neutral: NEW_PALETTE.grey10 },
    action: { active: NEW_PALETTE.grey90, ...COMMON.action },
    greyShade: { ...GREY_PALETTE.light },
  },
  dark: {
    ...COMMON,
    mode: "dark",
    primary: { ...PRIMARY_PALETTE.dark, contrastText: "#fff" },
    text: {
      primary: "#fff",
      secondary: GREY[500],
      disabled: GREY[600],
      greyLight: GREY[500],
      greyDark: NEW_PALETTE.grey35,
    },
    background: {
      paper: "#212529",
      default: GREY[900],
      neutral: DARK_PALETTE.secondaryLight,
    },
    action: { active: GREY[500], ...COMMON.action },
    secondary: {
      ...COMMON.secondary,
      main: DARK_PALETTE.secondary,
      light: DARK_PALETTE.secondaryLight,
      dark: DARK_PALETTE.secondaryDark,
      contrastText: "#fff",
    },
    error: {
      ...COMMON.error,
      light: DARK_PALETTE.errorLight,
      dark: DARK_PALETTE.errorDark,
      contrastText: "#fff",
    },
    info: {
      ...COMMON.info,
      light: DARK_PALETTE.adviceLight,
      dark: DARK_PALETTE.adviceDark,
      contrastText: "#fff",
    },
    success: {
      ...COMMON.success,
      light: DARK_PALETTE.successLight,
      dark: DARK_PALETTE.successDark,
      contrastText: "#fff",
    },
    warning: {
      ...COMMON.warning,
      light: DARK_PALETTE.adviceLight,
      dark: DARK_PALETTE.adviceDark,
      contrastText: "#fff",
    },
    greyShade: { ...GREY_PALETTE.dark },
  },
} as const;

export default palette;
