import { createSlice } from "@reduxjs/toolkit";
// import { dispatch } from "redux/store";

type SupplierState = {
  supplier: {
    email: string;
    found: boolean;
    id: string;
    isDeleted: boolean;
    name: string;
    isOnboardingDone: boolean;
    customOnboardingTemplate: string;
  };
};

const initialState: SupplierState = {
  supplier: {
    email: "",
    found: false,
    id: "",
    isDeleted: false,
    name: "",
    isOnboardingDone: false,
    customOnboardingTemplate: "",
  },
};

const slice = createSlice({
  name: "Supplier",
  initialState,
  reducers: {
    loginSupplierAction(state, action) {
      let {
        email,
        found,
        id,
        isDeleted,
        name,
        isOnboardingDone,
        customOnboardingTemplate,
      } = action.payload;
      state.supplier = {
        email,
        found,
        id,
        isDeleted,
        name,
        isOnboardingDone,
        customOnboardingTemplate,
      };
    },
    logoutSupplierAction(state) {
      state.supplier = {
        email: "",
        found: false,
        id: "",
        isDeleted: false,
        name: "",
        isOnboardingDone: false,
        customOnboardingTemplate: "",
      };
    },
    updateSupplierAction(state, action) {
      let { email, found, id, isDeleted, name, customOnboardingTemplate } =
        action.payload;
      state.supplier = {
        ...state.supplier,
        email,
        found,
        id,
        isDeleted,
        name,
      };
    },
    updateSupplierOnboardingAction(state, action) {
      let { email, found, id, isDeleted, name, isOnboardingDone } =
        action.payload;
      state.supplier = {
        ...state.supplier,
        email,
        found,
        id,
        isDeleted,
        name,
        isOnboardingDone,
      };
    },
  },
});

export default slice.reducer;

export const {
  loginSupplierAction,
  logoutSupplierAction,
  updateSupplierAction,
  updateSupplierOnboardingAction,
} = slice.actions;
