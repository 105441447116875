import LoadingScreen from "components/LoadingScreen";
import useAuth from "hooks/useAuth";
import EmailPageContainer from "pages/email_send_page/skeleton/EmailPageContainer";
import { useState, ReactNode, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "redux/store";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { allowed, isLoading } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const supplier = useSelector((state: any) => state.supplier.supplier);

  useEffect(() => {
    if (!allowed || !localStorage.getItem("token")) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
    }
  }, []);

  if (allowed && localStorage.getItem("token")) {
    const currentSupplierId = window.location.pathname.split("/")[1];
    console.log("currentSupplierId", currentSupplierId, supplier);
    if (
      !supplier?.isOnboardingDone &&
      pathname !== `/${currentSupplierId}/onboarding`
    ) {
      return <Navigate to={"/" + currentSupplierId + "/onboarding"} />;
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!allowed || !localStorage.getItem("token")) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <EmailPageContainer />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
