import axios from "axios";
function handleLogout() {
  localStorage.removeItem("supplierId");
  localStorage.removeItem("code");
  localStorage.removeItem("token");
  window.location.replace(`/${window.location.pathname.split("/")[1]}`);
}
export async function PostAsync(baseUrl, body, shouldUseToken = false) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        handleLogout();
      }
      return error.response;
    }
  );
}

export async function GetAsync(baseUrl, headers_ = {}, options = {}) {
  const currentSupplierId = window.location.pathname.split("/")[1];
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    token: localStorage.getItem("token"),
    supplierId: currentSupplierId,
    ...headers_,
  };

  // if (shouldUseToken) {
  //     let accessToken = await ValidateAccessToken();
  //     headers["Authorization"] = `Bearer ${accessToken}`;
  // }

  return await axios.get(baseUrl, { headers, ...options }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        handleLogout();
      }
      return error.response;
    }
  );
}

export async function PostFileAsync(baseUrl, body, headers_ = {}) {
  // const currentSupplierId = window.location.pathname.split("/")[1];
  let headers = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    token: localStorage.getItem("token"),
    ...headers_,
  };

  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        handleLogout();
      }
      return error.response;
    }
  );
}
export async function DeleteAsync(baseUrl, body, headers_ = {}) {
  const currentSupplierId = window.location.pathname.split("/")[1];
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    token: localStorage.getItem("token"),
    supplierId: currentSupplierId,
    ...headers_,
  };

  return await axios.delete(baseUrl, { data: body, headers: headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        handleLogout();
      }
      return error.response;
    }
  );
}

export async function PutAsync(baseUrl, body, _headers = {}) {
  const currentSupplierId = window.location.pathname.split("/")[1];
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    token: localStorage.getItem("token"),
    supplierId: currentSupplierId,
    ..._headers,
  };
  return await axios.put(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        handleLogout();
      }
      return error.response;
    }
  );
}
