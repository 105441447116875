import ThemeProvider from "./Theme";
import ThemeSettings from "../components/settings";
import { SettingsProvider } from "../contexts/SettingsContext";
import NotistackProvider from "components/NotistackProvider";

export default function ThemeSetter({ children }) {
  return (
    <SettingsProvider>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>{children}</NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </SettingsProvider>
  );
}
