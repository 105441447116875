import LoadingScreen from "components/LoadingScreen";
import AuthGuard from "guard/AuthGuard";
import GuestGuard from "guard/GuestGuard";
import { ElementType, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import lazyWithRetry from "./../lazyWithRetry";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen isDashboard={true} />}>
      <Component {...props} />
    </Suspense>
  );
};

const Page404 = Loadable(lazyWithRetry(() => import("pages/Page404")));
const Profile = Loadable(lazyWithRetry(() => import("pages/accounts")));
const Onboarding = Loadable(lazyWithRetry(() => import("pages/onboarding")));
const SupplierPageContainer = Loadable(
  lazyWithRetry(() => import("pages/supplier_page/SupplierPageContainer"))
);
const EmailPageContainer = Loadable(
  lazyWithRetry(
    () => import("../pages/email_send_page/skeleton/EmailPageContainer")
  )
);
const ImpersonateSupplier = Loadable(
  lazyWithRetry(() => import("../pages/impersonate"))
);

const Router = () => {
  const supplierId = localStorage.getItem("supplierId");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthGuard>
              <EmailPageContainer />
            </AuthGuard>
          }
        />
        <Route
          path="/:id/onboarding"
          element={
            <AuthGuard>
              <Onboarding />
            </AuthGuard>
          }
        />
        <Route
          path="/:id/documents"
          element={
            <AuthGuard>
              <SupplierPageContainer />
            </AuthGuard>
          }
        />
        <Route
          path="/:id/account"
          element={
            <AuthGuard>
              <Profile />
            </AuthGuard>
          }
        />
        <Route path="/:id/impersonate" element={<ImpersonateSupplier />} />
        <Route
          path="/:id"
          element={
            <GuestGuard>
              <EmailPageContainer />
            </GuestGuard>
          }
        />
        <Route path="/:id/404" element={<Page404 />} />
        <Route path="/404" element={<Page404 />} />
        <Route
          path="*"
          element={
            <Navigate to={supplierId ? `${supplierId}/404` : "/404"} replace />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
