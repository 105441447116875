import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        h3: {
          fontSize: "36px",
          fontWeight: 700,
          color: "#212B36",
        },
        h6: {
          fontSize: "20px",
          fontWeight: 700,
          color: "#212B36",
        },
        caption: {
          fontWeight: 700,
          fontSize: "12px",
          color: "#212B36",
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
