import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

const ChevronDown = ({
  color = "secondary",
  width = 15,
  height = 15,
  customColor,
  sx,
  className = "",
}: SvgIconProps & {
  customColor?: string;
  sx?: any;
  className?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 0,
        ...sx,
      }}
      className={className}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8748 8L11.9374 16L4 8"
          stroke={customColor ?? theme.palette.primary.main}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default ChevronDown;
