import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NewTextField from "../../../components/form/TextField";

export default function EmailForm(props: any) {
  const { handleSendEmail } = props;
  const { t } = useTranslation();

  return (
    <form
      action="/"
      method="POST"
      onSubmit={handleSendEmail}
      style={{
        maxWidth: "500px",
      }}
    >
      <DialogTitle variant="h4" fontWeight={700}>
        {t("email.enterEmail")}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom color={"#7c8a96"}>
          {t("email.enterEmailCtx")}
        </Typography>
        <NewTextField
          autoFocus
          required
          id="email"
          label={"Email"}
          type="email"
        />
        <DialogActions
          sx={{
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: 2,
          }}
        >
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            onSubmit={handleSendEmail}
          >
            {t("email.sendCode")}
          </Button>
        </DialogActions>
      </DialogContent>
    </form>
  );
}
