import { SettingsValueProps } from "./components/settings/type";
import { enUS, esES } from "@mui/material/locale";

export const defaultSettings: SettingsValueProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: "horizontal",
  themeColorPresets: "default",
  themeStretch: false,
};

export const allLangs = [
  {
    label: "Spanish",
    value: "es",
    systemValue: esES,
    icon: "/assets/icons/flags/ic_flag_eS.png",
    disabled: false,
  },
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/assets/icons/flags/ic_flag_en.svg",
    disabled: false,
  },
];

export const defaultLang = allLangs[0]; // Spanish
