import { inputLabelClasses, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function CodeInput(props) {
  const { register, position, handleFill, handlePaste, handleDelete } = props;
  const theme = useTheme();

  return (
    <TextField
      autoFocus={position === "code1" ? true : false}
      // focused={position === "code" + currentFocus ? true : false}
      required
      type="number"
      {...register(position)}
      margin="dense"
      id={position}
      variant="outlined"
      onChange={handleFill}
      onPaste={handlePaste}
      onKeyDown={handleDelete}
      InputProps={{
        inputProps: {
          minLength: 1,
          maxLength: 1,
        },
      }}
      InputLabelProps={{
        style: {
          color: "#a6b1bb",
        },
      }}
      sx={{
        input: {
          color: "black",
          textAlign: "center",
        },
        fieldset: {
          borderColor: theme.palette.secondary.main,
          [`&.${inputLabelClasses.shrink}`]: {
            borderColor: theme.palette.secondary.main,
          },
          "&:focus": {
            borderColor: theme.palette.secondary.main,
          },
        },
        maxWidth: "56px",
      }}
    />
  );
}
