import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: theme.palette.primary.accent,
          height: 4,
        },
        paddimg: "2px",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: 13,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          color: theme.palette.primary.light,
          "&.Mui-selected": {
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightBold,
          },
          "&:not(:last-of-type)": {
            marginRight: theme.spacing(2),
          },
          "@media (min-width: 600px)": {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 32,
          flexDirection: "row",
          "& > *:first-of-type": {
            marginBottom: 0,
            marginRight: theme.spacing(1),
          },
        },
        wrapper: {
          flexDirection: "row",
          whiteSpace: "nowrap",
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: "50%",
        },
      },
    },
  };
}
