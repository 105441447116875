import Router from "routes";
import ThemeSetter from "./theme/ThemeSetter";
import { AuthProvider } from "contexts/AuthContext";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "redux/store";
function App() {
  return (
    <AuthProvider>
      <ReduxProvider store={store}>
        <ThemeSetter>
          <Router />
        </ThemeSetter>
      </ReduxProvider>
    </AuthProvider>
  );
}

export default App;
