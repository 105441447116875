import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import supplier from "./slices/supplier";
import emailPage from "./slices/emailPage";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const supplierPersistConfig = {
  key: "supplier",
  storage,
  keyPrefix: "redux-",
  whitelist: ["supplier"],
};

const pagePersistConfig = {
  key: "page",
  storage,
  keyPrefix: "redux-",
  whitelist: ["page"],
};

const rootReducer = combineReducers({
  page: persistReducer(pagePersistConfig, emailPage),
  supplier: persistReducer(supplierPersistConfig, supplier),
});

export { rootPersistConfig, rootReducer };
