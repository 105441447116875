import { Stack, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NewDostLogo from "../../../assets/logo/newDostLogo.png";

import cssStyles from "./../../../utils/cssStyles";
import LanguagePopover from "../../supplier_page/header/LanguagePopover";

export default function EmailHeader({
  isCollapse = false,
  verticalLayout = false,
}) {
  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={true}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 2 },
        }}
      >
        <img src={NewDostLogo} style={{ width: "100px" }} />
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="start"
          sx={{ mr: 2 }}
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

export const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: 70,
  width: "100%",
  zIndex: 1000,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.up("lg")]: {
    width: `calc(100%)`,
    ...(isCollapse && {
      width: `calc(100% - 500px)`,
    }),
    ...(isOffset && {
      height: 70,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: 70,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));
