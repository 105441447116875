import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
  name: "pageState",
  initialState: {
    value: 0,
  },
  reducers: {
    emailState: (state) => {
      state.value = 0;
    },
    codeState: (state) => {
      state.value = 1;
    },
    uploadState: (state) => {
      state.value = 2;
    },
  },
});

export const { emailState, codeState, uploadState } = pageSlice.actions;

export default pageSlice.reducer;
