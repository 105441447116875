import { useTranslation } from "react-i18next";
import useSettings from "./useSettings";
// config
import { allLangs, defaultLang } from "../config";

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation();

  const { onChangeDirectionByLang } = useSettings();

  const langStorage = localStorage.getItem("language");

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    localStorage.setItem("language", newlang);
    onChangeDirectionByLang(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => t(text, options)?.toString(),
    currentLang,
    allLangs,
  };
}
