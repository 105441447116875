import { m } from "framer-motion";
import { forwardRef, ReactNode } from "react";
// @mui
import { Box, IconButton, IconButtonProps } from "@mui/material";

// ----------------------------------------------------------------------

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = "small", ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton
        sx={{
          bgcolor: "grey.300",
        }}
        size={size}
        ref={ref}
        {...other}
      >
        {children}
      </IconButton>
    </AnimateWrap>
  )
);

export default IconButtonAnimate;

// ----------------------------------------------------------------------

type AnimateWrapProp = {
  children: ReactNode;
  size: "small" | "medium" | "large";
};

function AnimateWrap({ size, children }: AnimateWrapProp) {
  const isSmall = size === "small";
  const isLarge = size === "large";

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      // variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: "inline-flex",
      }}
    >
      {children}
    </Box>
  );
}
